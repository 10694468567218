import _domparser from "./domparser";
import _utilities from "./utilities";
var exports = {};
var domparser = _domparser;
var utilities = _utilities;
var formatDOM = utilities.formatDOM;
var isIE9 = utilities.isIE(9);
var DIRECTIVE_REGEX = /<(![a-zA-Z\s]+)>/; // e.g., <!doctype html>

/**
 * Parses HTML and reformats DOM nodes output.
 *
 * @param  {String} html - The HTML string.
 * @return {Array}       - The formatted DOM nodes.
 */

function parseDOM(html) {
  if (typeof html !== "string") {
    throw new TypeError("First argument must be a string");
  }

  if (!html) {
    return [];
  } // match directive


  var match = html.match(DIRECTIVE_REGEX);
  var directive;

  if (match && match[1]) {
    directive = match[1]; // remove directive in IE9 because DOMParser uses
    // MIME type 'text/xml' instead of 'text/html'

    if (isIE9) {
      html = html.replace(match[0], "");
    }
  }

  return formatDOM(domparser(html), null, directive);
}

exports = parseDOM;
export default exports;